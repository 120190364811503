


















































































import {Component, Vue} from "vue-property-decorator";
import {filterTableData} from "@/utils/table";
import {RequestPaging} from "@/utils/util";
import LsPagination from "@/components/ls-pagination.vue";
import {apiGetAllTopic} from "@/api/goods";

@Component({
  components: {
    LsPagination
  }
})
export default class WorkBenchDialog extends Vue {
  $refs!: { dialogTable: any }
  dialogVisible: boolean = false
  dialogTableData: any = []
  multipleSelection: any = []
  haveData: any = []
  pager: RequestPaging = new RequestPaging();
  form: any = {
    keywords: '',
    order_by_field: '',
    order_val: '',
    topic_id: '',
    work_bench_type: 1,
    status: 1,
    create_time: '',
    online_status: '0, 2',
  }
  statusFilters: any = [
    {
      text: '未上线', value: 0
    }, {
      text: '已上线', value: 1
    }, {
      text: '已下线', value: 2
    }
  ]

  // 创建人筛选
  get filtersCreateName() {
    return filterTableData(this.dialogTableData, 'create_name')
  }

  // 最后修改人筛选
  get filtersLastName() {
    return filterTableData(this.dialogTableData, 'last_name')
  }


  rowStyle() {
    return 'text-align:center'
  }

  /**
   * 获取所有主题并且过滤掉工作台已有的数据
   */
  getAllTopic() {
    if (this.multipleSelection.length > 0) {
      this.$nextTick(() => {
        this.$refs.dialogTable.clearSelection()
      })
    }
    this.dialogTableData.length = 0
    this.pager.request({
      callback: apiGetAllTopic,
      params: {
        ...this.form
      }
    }).then((res: any) => {
      let {lists} = res
      let data: any = []
      if (this.haveData.length === 0) {
        return this.dialogTableData = lists
      }
      // if (this.haveData.length !== 0) {
      lists.forEach((i: any) => {
        const isHave = this.haveData.some((j: any) => i.id === j.id)
        if (!isHave) {
          data.push(i)
        }
      })
      // }
      this.dialogTableData = data
    })
  }

  dialogClick() {
    if (this.multipleSelection.length === 0) {
      return this.$message.error('请选择您想添加的数据！')
    }
    this.form.keywords = ''
    let data: any = []
    this.multipleSelection.forEach((item: any) => {
      data.push({
            id: item.id,
            name: item.name,
            name_en: item.name_en,
            note: item.note,
            topic_index_count: item.topic_index_count,
            url: item.url,
            create_time: item.create_time,
            create_name: item.create_name,
            update_time: item.update_time,
            last_name: item.last_name,
            topic_id: item.id,
            sort: item.sort,
            status: item.status,
            online_status: item.online_status,
            isNew: true
          }
      )
    })
    this.$emit('AddData', data)
    this.dialogVisible = false
  }

// 状态格式化
  statusFormat(row: any) {
    switch (row.online_status) {
      case 0:
        return '未上线';
      case 1:
        return '已上线';
      case 2:
        return '已下线';
    }
  }

// 选中的时候的状态
  handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }

  // 状态筛选
  filterMethodStatus(value: any, row: any) {
    return row.online_status == value;
  }

  // 创建人筛选
  filterMethodCreateName(value: any, row: any) {
    return row.create_name == value;
  }

  // 最后修改人筛选
  filterMethodLastName(value: any, row: any) {
    return row.last_name == value;
  }
}
