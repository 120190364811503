



































import {Component, Vue} from "vue-property-decorator";
import WorkbenchPanel from "@/components/goods/workbench-panel.vue";
import {apiEditWorkbench, apiGetWorkbenchByType} from "@/api/goods";
import WorkBenchDialog from "@/components/goods/WorkBenchDialog.vue";
import {commonConfirm} from "@/utils/confirm";

@Component({
  components: {
    WorkbenchPanel,
    WorkBenchDialog
  }
})
export default class Work extends Vue {
  $refs!: { table: any, dialog: any }
  isChange: boolean = true
  isBtnCanClick: boolean = true
  form: any = {
    keywords: '',
    order_by_field: '',
    order_val: '',
    topic_id: '',
    work_bench_type: 1,
    status: 1,
    create_time: '',
    online_status: '0, 2',
  }
  tableData: any = []

  dataChanged() {
    this.isChange = false
    // this.getTableData()
  }

  canClick(arg: any) {
    this.isBtnCanClick = arg
  }

  // 批量下线
  delWorkbench() {
    commonConfirm(`确定移出选中的${this.$refs.table.multipleSelection.length}个主题吗？`, '下线提示', () => {
      const ids = this.$refs.table.multipleSelection.map((item: any) => item.id)
      ids.forEach((i: any) => {
        this.tableData = this.tableData.filter((j: any) => !(i === j.id))
      })
      this.$refs.table.clearSelection()
      this.isChange = false
    })
  }

  showDialog() {
    this.$refs.dialog.form.keywords = ''
    this.$refs.dialog.form.work_bench_type = this.form.work_bench_type
    this.$refs.dialog.haveData = this.tableData
    this.$refs.dialog.getAllTopic()
    this.$refs.dialog.dialogVisible = true
  }

  handleTabClick(tab: any) {
    this.$refs.table.clearSelection();
    this.isChange = true
    this.form.work_bench_type = Number(tab.index) + 1
    this.getTableData()
  }

//获取工作台列表
  getTableData() {
    apiGetWorkbenchByType({
      work_bench_type: this.form.work_bench_type
    }).then((res: any) => {
      this.tableData.length = 0
      if (res === null) {
        return this.tableData = []
      }
      res.forEach((item: any) => {
        this.tableData.push({
              id: item.id,
              name: item.topic.name,
              name_en: item.topic.name_en,
              note: item.topic.note,
              topic_index_count: item.topic_index_count,
              url: item.topic.url,
              create_time: item.topic.create_time,
              create_name: item.topic.create_name,
              update_time: item.topic.update_time,
              last_name: item.topic.last_name,
              topic_id: item.topic_id,
              sort: item.sort,
              status: item.status,
              online_status: item.topic.online_status
            }
        )
      })
    })
  }

  editWorkbench() {
    this.$confirm('确定将线上资源区域内的主题发布到现网吗?', '上线提示').then(async () => {
      let data: any = []
      this.tableData.forEach((item: any) => {
        data.push({
          topic_id: item.topic_id,
          sort: item.sort,
          status: 1,
        })
      })
      apiEditWorkbench({
        work_bench_type: this.form.work_bench_type,
        data
      }).then(() => {
        this.$message.success('发布成功！')
        this.isChange = true
        this.$refs.table.clearSelection()
        this.getTableData()
      })
    }).catch(r => {
    })
  }

  addData(data: any) {
    data.forEach((item: any) => {
      this.tableData.unshift(item)
    })
    this.isChange = false
  }

  // 上下移动 1上移0下移
  async move(type: number) {
    this.isChange = false
    const selectData = this.$refs.table.multipleSelection
    const selectIndex: any = []
    selectData.forEach((val: any) => {
      this.tableData.forEach((v: any, i: any) => {
        if (val.id === v.id) {
          selectIndex.push(i)
        }
      })
    })
    type === 1 ? selectIndex.sort() : selectIndex.sort((a: any, b: any) => b - a)
    let newData = this.tableData
    this.tableData = []
    selectIndex.forEach((index: number) => {
      let neighbor: any = type === 1 ? index - 1 : index + 1
      if (neighbor < 0) {
        neighbor = 0
      } else if (neighbor > newData.length - 1) {
        neighbor = newData.length - 1
      }
      let data = newData[neighbor]
      newData[neighbor] = newData[index]
      newData[index] = data
    })
    for (let i = 0; i < newData.length; i++) {
      newData[i].sort = i
    }
    newData.forEach((item: any) => {
      this.tableData.push(item)
    })
    // await apiEditWorkbench({
    //   work_bench_type: this.form.work_bench_type,
    //   data: this.tableData
    // })
  }

  setTopOrBottom(type: number) {
    this.isChange = false
    const selectData = this.$refs.table.multipleSelection
    const selectIndex: any = []
    selectData.forEach((val: any) => {
      this.tableData.forEach((v: any, i: any) => {
        if (val.id === v.id) {
          selectIndex.push(i)
        }
      })
    })
    selectIndex.sort()
    // 根据索引遍历，删除索引所在的值，存入data中
    let data: any = []
    selectIndex.forEach((item: any) => {
      data.push(this.tableData[item])
      delete this.tableData[item]
    })
    this.tableData = this.tableData.filter((item: any) => item)
    data.forEach((item: any) => {
      if (type === 1) {
        this.tableData.unshift(item)
      } else if (type === 0) {
        this.tableData.push(item)
      }
    })
    for (let i = 0; i < this.tableData.length; i++) {
      this.tableData[i].sort = i
    }
    // apiEditWorkbench({
    //   work_bench_type: this.form.work_bench_type,
    //   data: this.tableData
    // })
  }


  created() {
    this.getTableData()
  }
}
