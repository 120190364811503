import { render, staticRenderFns } from "./workbench.vue?vue&type=template&id=502aa36a&scoped=true&"
import script from "./workbench.vue?vue&type=script&lang=ts&"
export * from "./workbench.vue?vue&type=script&lang=ts&"
import style0 from "./workbench.vue?vue&type=style&index=0&id=502aa36a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "502aa36a",
  null
  
)

export default component.exports