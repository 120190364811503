






























































































import {filterTableData} from '@/utils/table'
import {Component, PropSync, Vue, Watch} from 'vue-property-decorator'

@Component
export default class WorkbenchPanel extends Vue {
  $refs!: { table: any }
  @PropSync('data') value: any
  multipleSelection: any = []
  statusFilters: any = [{
    text: '未上线', value: 0
  }, {
    text: '已上线', value: 1
  }, {
    text: '已下线', value: 2
  }]

  // 创建人筛选
  get filtersCreateName() {
    return filterTableData(this.value, 'create_name')
  }

  // 最后修改人筛选
  get filtersLastName() {
    return filterTableData(this.value, 'last_name')
  }

  @Watch('multipleSelection')
  onMultipleSelectionChange(newVal: any) {
    if (newVal.length > 0) {
      this.$emit('canClick', false)
    } else {
      this.$emit('canClick', true)
    }
  }

  // 状态筛选
  filterMethodOnlineStatus(value: any, row: any) {
    return row.online_status == value;
  }

  // 状态格式化
  statusFormat(row: any) {
    switch (row.online_status) {
      case 0:
        return '未上线';
        break
      case 1:
        return '已上线';
        break
      case 2:
        return '已下线';
        break
    }
  }

  // 创建人筛选
  filterMethodCreateName(value: any, row: any) {
    return row.create_name == value;
  }

// 最后修改人筛选
  filterMethodLastName(value: any, row: any) {
    return row.last_name == value;
  }

  rowStyle() {
    return "text-align:center";
  }

  // 选中后
  handleSelectionChange(val: any) {
    this.multipleSelection = val
  }

  // 配合table，使得页面刷新不会取消勾选
  getRowKey(row: any) {
    return row.id
  }

  clearSelection() {
    this.$refs.table.clearSelection();
  }


  // 把每一行的索引放进row
  tableRowClassName({row, rowIndex}: any) {
    row.index = rowIndex;
  }

  // 删除当前行
  deleteRow(scope: any) {
    // if (scope.row.isNew) {

    // }
    this.$confirm('确定下线?', '下线提示').then(async () => {
      this.value.splice(scope.$index, 1)
      // await apiDelWorkbenchItem({
      //   data: [{
      //     id: scope.row.id,
      //     topic_id: scope.row.topic_id
      //   }]
      // })
      // this.clearSelection()
      this.$emit('delete-row')
      // this.$message({
      //   type: 'success',
      //   message: '下线成功!'
      // });
    }).catch(() => {
    })
  }
}
